import React from "react"
import { SectionType } from "../../../types"

type SectionProps = {
  section: SectionType
  index: any
  [key: string]: any
}

const GoogleReviewsSection = ({ section, index, ...props }: SectionProps) => {

  return (
    <div className={`relative w-full py-20`}>
      <div className="container px-0">
        <div className={`px-4 sm:px-6 xl:px-0 flex`}>
          <div className="grid md:grid-cols-2">
            <div>
              <img src="/images/uploads/uhsm-google-reviews-2.png" alt="Google Reviews" />
            </div>
            <div>
              <img src="/images/uploads/uhsm-google-reviews-3.png" alt="Google Reviews" />
            </div>
            <div>
              <img src="/images/uploads/uhsm-google-reviews-4.png" alt="Google Reviews" />
            </div>
            <div>
              <img src="/images/uploads/uhsm-google-reviews-5.png" alt="Google Reviews" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GoogleReviewsSection
